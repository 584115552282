<template>
  <div class="b2c-page-bg">  
    <div class="b2c-o-h">
      <div class="w-100 b2c-bg fs-sp">
        <div class="w-100 b2c-bg-top">
          <div class="b2c-top-left" @click="$router.push({path:'/'})"></div>
          <div class="b2c-top-center">Tra cứu vận đơn</div>
          <div class="b2c-top-right"></div>
        </div>
      </div>
      <div class="pt-0 pl-4 pr-4 pb-3 bg-primary">
        <div class="group-order-search">
          <input type="text" @keydown.enter="search()" v-model="OrderId" placeholder="Nhập mã đơn hàng" class="b2c-shipment-input"/>
          <button class="btn-order-search" @click="search()" >
            <img src="../assets/images/icons/search-icon.svg" alt=""/>
          </button>
        </div>
      </div>
      <div class="w-100 b2c-float-bg" v-if="!isLoading">
      </div>
      <div class="w-100 pb-2 pr-2 pl-2 pt-0" v-if="!isLoading && showResult">
        <b-card body-class="pl-1 pr-1 pt-2" class="b2c-package-bg-selected min-400">
          <b-card-text v-if="shipment==null" class=" text-center">
            <img src="../assets/images/shipment.svg" alt="" class="w-100"/>
            <span class="h1 pt-2">Không có thông tin đơn hàng</span>
          </b-card-text>
          <b-card-text v-else-if="shipment.device">
            <img src="../assets/images/shipment.svg" alt="" class="w-100 mb-2"/>
            <b-row v-if="shipment.OrderId">
              <b-col cols="12" class="h2">Đơn hàng: <span class="text-primary">{{shipment.OrderId}}</span></b-col>
              <b-col cols="12" class="h2">Tên khách hàng: <span class="text-primary">{{shipment.FullName}}</span></b-col>
              <b-col cols="12" class="h2">Số liên hệ: <span class="text-primary">{{shipment.ContactPhone}}</span></b-col>
              <b-col cols="12" class="h2">Địa chỉ:<p class="h3">{{shipment.Addr}}</p></b-col>
              <b-col cols="12" class="h2 pb-2 pt-2">Xem thông tin đơn hàng <a :href="'/thong-tin-don-hang/'+shipment.OrderId"><b class="text-primary">tại đây</b></a></b-col>
            </b-row>
            <table class="w-100" v-if="shipment.shipment && shipment.shipment.length>0">
              <tr v-for="i in shipment.shipment" :key="i.status">
                <td class="b2c-tab-shipment-col1"></td>
                <td class="b2c-tab-shipment-col2">
                  <h3><feather-icon icon="CalendarIcon"/> {{i.date_}}</h3>
                  <p>{{ghtkStatus.find(m=>m.id==i.status).text}}</p>
                </td>
              </tr>
            </table>
            <span v-else-if="shipment && shipment.device && shipment.device.cart.find(i=>i.product_type==100)" class="text">Đơn hàng giao SIM của Quý Khách đang được xử lý và chuyển tới đơn vị vận chuyển</span>
            <div v-if="shipment.GHTK_Id" class="text pt-1">Xem chi tiết đơn hàng giao thiết bị tại đây <a :href="'https://jtexpress.vn/vi/tracking?type=track&billcode='+shipment.GHTK_Id"><b class="text-primary">xem tại đây</b></a></div>

          </b-card-text>
          <b-card-text v-else>
            <b-row v-if="shipment.OrderId>0">
              <b-col cols="12" class="h2"><img src="../assets/images/shipment.svg" alt="" class="w-100"/></b-col>
              <b-col cols="12" class="h2">Đơn hàng: <span class="text-primary">{{shipment.OrderId}}</span></b-col>
              <b-col cols="12" class="h2">Tên khách hàng: <span class="text-primary">{{shipment.FullName}}</span></b-col>
              <b-col cols="12" class="h2">Số liên hệ: <span class="text-primary">{{shipment.ContactPhone}}</span></b-col>
              <b-col cols="12" v-if="shipment.hassim>0" class="h2">Địa chỉ:<p class="h3">{{shipment.Addr}}</p></b-col>
            </b-row>
            <b-row v-if="shipment.OrderId>0">
              <b-col cols="12" v-if="shipment.hassim>0" class="h3 pt-2 border-bottom pb-1 mb-2">Thông tin vận chuyển:</b-col>
              <b-col cols="12" v-else class="h3 pt-2 border-bottom pb-1 mb-2">Thông tin đơn hàng:</b-col>
            </b-row>
            <table class="w-100" v-if="shipment.IsFast!=1 && shipment.shipment && shipment.shipment.length>0">
              <tr v-for="i in shipment.shipment" :key="i.status">
                <td class="b2c-tab-shipment-col1"></td>
                <td class="b2c-tab-shipment-col2">
                  <h3><feather-icon icon="CalendarIcon"/> {{i.date_}}</h3>
                  <p>{{ghtkStatus.find(m=>m.id==i.status).text}}</p>
                </td>
              </tr>
            </table>
            <table class="w-100" v-if="shipment.IsFast==1 && shipment.shipment && shipment.shipment.length>0">
              <tr v-for="i in shipment.shipment" :key="i.status">
                <td class="b2c-tab-shipment-col1"></td>
                <td class="b2c-tab-shipment-col2">
                  <h3><feather-icon icon="CalendarIcon"/> {{i.date_}}</h3>
                  <p>{{ahmStatus.find(m=>m.id==i.status).text}}</p>
                </td>
              </tr>
              <tr><td colspan="2" class="b2c-tab-shipment-col2">
                <p>Để theo dõi hành trình đơn hàng, <b><a target="_blank" class="text-primary" :href="shipment.shipmentInfo.shared_link">Click vào đây</a></b></p>
              </td></tr>
            </table>
            <span v-else-if="shipment.hassim>0" class="text">Đơn hàng của Quý Khách đang được xử lý và chuyển tới đơn vị vận chuyển</span>
            <span v-else class="text">Cảm ơn Quý Khách đã sử dụng dịch vụ của iTel. Đơn hàng {{OrderId}} của Quý khách đã được xử lý. Quý khách vui lòng kiểm tra email và làm theo hướng dẫn. Hỗ trợ LH 0877 087 087</span>
            
          </b-card-text>
        </b-card>
      </div>
    </div>
    
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required,min,email } from '@validations'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import Select2 from 'v-select2-component';
import { BCard,VBTooltip, BRow, BCol,BButton, BFormInput, BPagination, BCardText,BFormGroup,BFormCheckboxGroup, BFormCheckbox, BFormRadio, BFormRadioGroup,BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import { WebCam } from "vue-web-cam";
import Vue from 'vue'
import vSelect from 'vue-select'
import StarRating from 'vue-star-rating'
Vue.component('v-select', vSelect)

extend('msisdn', value => {
  const first4 = value.slice(0, 4);
  const first3 = value.slice(0, 3);
  if (first4 !== '8487' && first3 !== '087') {
    return false;
  }
  else if (first4 === '8487' && value.length !== 11
    || first3 === '087' && value.length !== 10) {
    return false;
  }
  return true;
});

extend('num', value => {
  return !/\D/.test(value);
});

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images['assets/images/ve-itel/'+item.replace('./', '')] = r(item);    
  });
  return images;
}
const images = importAll(require.context('@/assets/images/ve-itel/', false, /.(png)$/));


export default {
  components: {
    Select2,BCard, BRow, BCol,BFormInput,BFormGroup,
    ValidationProvider, ValidationObserver,BFormRadio, BFormRadioGroup,BFormCheckboxGroup,
    BInputGroup, BInputGroupAppend,BButton,FeatherIcon,BFormCheckbox,BCardText,BPagination,
    'vue-web-cam': WebCam,VBTooltip,StarRating
  },
  data() {
    return {
      isLoading:false,
      shipment:[],
      OrderId:null,
      showResult: false,
      ahmStatus:[
        {id:'IDLE',text:'Đã tiếp nhận'},
        {id:'ASSIGNING',text:'Đã điều phối lấy hàng'},
        {id:'ACCEPTED',text:'Đã tiếp nhận'},
        {id:'IN PROCESS',text:'Shipper báo đã lấy hàng'},
        {id:'COMPLETED',text:'Hoàn thành đơn hàng'},
        {id:'CANCELLED',text:'Hủy đơn hàng'},
      ],
      ghtkStatus:[
        {id:'-1',text:'Hủy đơn hàng'},
        {id:'1',text:'Chưa tiếp nhận'},
        {id:'2',text:'Đã tiếp nhận'},
        {id:'3',text:'Đã lấy hàng/Đã nhập kho'},
        {id:'4',text:'Đã điều phối giao hàng/Đang giao hàng'},
        {id:'5',text:'Đã giao hàng/Chưa đối soát'},
        {id:'6',text:'Đã đối soát'},
        {id:'7',text:'Không lấy được hàng'},
        {id:'8',text:'Hoãn lấy hàng'},
        {id:'9',text:'Không giao được hàng'},
        {id:'10',text:'Delay giao hàng'},
        {id:'11',text:'Đã đối soát công nợ trả hàng'},
        {id:'12',text:'Đã điều phối lấy hàng/Đang lấy hàng'},
        {id:'13',text:'Đơn hàng bồi hoàn'},
        {id:'20',text:'Đang trả hàng (COD cầm hàng đi trả)'},
        {id:'21',text:'Đã trả hàng (COD đã trả xong hàng)'},
        {id:'123',text:'Shipper báo đã lấy hàng'},
        {id:'127',text:'Shipper (nhân viên lấy/giao hàng) báo không lấy được hàng'},
        {id:'128',text:'Shipper báo delay lấy hàng'},
        {id:'45',text:'Shipper báo đã giao hàng'},
        {id:'49',text:'Shipper báo không giao được giao hàng'},
        {id:'410',text:'Shipper báo delay giao hàng'},
      ]
    }
  },
  watch: {
    $route(to, from) {
      this.step=1;
    },
    OrderId: function (newValue, oldValue) {
      localStorage.currentOrderId = newValue
    }
  },
  created() {
    
  },
  mounted(){
    document.title = 'iTel - Tra cứu đơn hàng';
    if (this.$route.params.id!=undefined){
      this.OrderId = this.$route.params.id
    }
    this.search();
  },
  methods: {
    search(){
      if (this.OrderId!=null){
        this.loading(true);
        useJwt.get('shipment-tracking/'+this.OrderId).then(response =>{
          this.shipment = response.data.result;
          console.log(this.shipment);
          this.showResult = true;
          this.loading(false);
        }).catch(err=>{
          this.loading(false);
          this.$toast.error('Có lỗi trong khi lấy thông tin đơn hàng, Quý khách vui lòng thử lại!', {icon:true, closeButton: "button"})
        })
      }
    },
    
  }
}
</script>
<style>
.group-order-search{
  display: flex;
  width: 100%;
}
.btn-order-search{
  width: 60px;
  background: #FFFFFF;
  margin-left: 10px;
  border-radius: 10px;
  font-size: 14px;
}
.table-step{
  width: 100%;
}
.table-step td{
  width: 25%;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  padding-top: 0.5rem;
}
.b2c-bg-top{
  background: rgba(237, 31, 36, 1);
  display: flex;
  margin-bottom: 0px;
}
.b2c-top-left::before{
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
}
.b2c-top-left{
  height: 44px;
  padding:8px 14px;
  width: 20%;
}
.b2c-top-right{
  width: 20%;
}
.b2c-top-right1::before{
  width: 20%;
}
.b2c-top-center{
  width: 80%;
  height: 22px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  padding-top: 7px;
}
.b2c-steps{
  width: 100%;
  height: 42px;
  background: #D71A1F;
}

.b2c-text-solo{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #FFFFFF;
  text-align: center;
}
.b2c-bg{
  background: rgba(237, 31, 36, 1);
  padding-bottom: 32px;
}
.b2c-number-087{
  width: 25%;
  text-align: right;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 36px;
  color: #fff;
  padding-top: 3px;
}
.b2c-number-msisdn{
  width: 50%;
  padding: 0px 10px;
}
.b2c-number-input::placeholder{
  color: rgba(0,0,0,0.3);
  font-size: 16px;
  text-indent: 10px;
  letter-spacing: 0px;
  padding-left: 0px;
}
.b2c-number-input{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  height: 45px;
  color: #ED1F24;
  width: 100%;
  border: none;
  background: #fff;
  -webkit-border-radius:8px;
	border-radius:8px;
  letter-spacing: 3px;
  padding-left: 10px;
}
.b2c-number-btn{
  width: 25%;
}
.b2c-number-btn button{
  border: 1px solid #fff;
  width: 49px;
  height: 45px;
  -webkit-border-radius:8px;
	border-radius:8px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE4TDE0LjIyMjMgMTQuMjE1NkwxOCAxOFpNMTYuMzE1OCA5LjE1Nzg5QzE2LjMxNTggMTEuMDU2MyAxNS41NjE3IDEyLjg3NjkgMTQuMjE5MyAxNC4yMTkzQzEyLjg3NjkgMTUuNTYxNyAxMS4wNTYzIDE2LjMxNTggOS4xNTc4OSAxNi4zMTU4QzcuMjU5NSAxNi4zMTU4IDUuNDM4ODYgMTUuNTYxNyA0LjA5NjUgMTQuMjE5M0MyLjc1NDEzIDEyLjg3NjkgMiAxMS4wNTYzIDIgOS4xNTc4OUMyIDcuMjU5NSAyLjc1NDEzIDUuNDM4ODYgNC4wOTY1IDQuMDk2NUM1LjQzODg2IDIuNzU0MTMgNy4yNTk1IDIgOS4xNTc4OSAyQzExLjA1NjMgMiAxMi44NzY5IDIuNzU0MTMgMTQuMjE5MyA0LjA5NjVDMTUuNTYxNyA1LjQzODg2IDE2LjMxNTggNy4yNTk1IDE2LjMxNTggOS4xNTc4OVY5LjE1Nzg5WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==') no-repeat center;
}
.b2c-number--container{
  padding: 0 25px;
  height: 36px;
  background: #D71A1F;
  width: 100%;
}
.b2c-number-except{
  margin: 0 25px;
  height: 46px;
  background: #D71A1F;
  width: calc(100% - 50px);
  -webkit-border-radius:5px;
	border-radius:5px;
  display: flex;
  vertical-align:middle;
  margin-top: 9px;
}
.b2c-number-except-e{
  margin: 0 25px;
  height: auto;
  background: #D71A1F;
  width: calc(100% - 50px);
  -webkit-border-radius:5px;
	border-radius:5px;
  display: flex;
  vertical-align:middle;
  margin-top: 9px;
}
.b2c-number-type{
  margin-left:25px;
  margin-right:25px;
  height: 46px;
  background: #D71A1F;
  width: calc(100% - 50px);
  -webkit-border-radius:5px;
	border-radius:5px;
  display: flex;
  vertical-align:middle;
  margin-top: 9px;
}
.b2c-number-price{
  margin: 0 25px;
  height: 46px;
  background: #D71A1F;
  width: calc(100% - 50px);
  -webkit-border-radius:5px;
	border-radius:5px;
  display: flex;
  vertical-align:middle;
  margin-top: 30px;
}
.b2c-number-except-text{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}
.b2c-number-except-buttons{
  padding: 0px;
  min-height: 46px;
  overflow: hidden;
}
.b2c-number-except-btn{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K') no-repeat;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin:8px 8px 8px 0px;
  color: #fff;
  width: 29px;
  height: 29px;
}
.b2c-number-except-btn-checked{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTQuNSIgY3k9IjE0LjUiIHI9IjEzLjUiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==') no-repeat;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin:8px 8px 8px 0px;
  color: #ED1F24;
  width: 29px;
  height: 29px;
}
.b2c-number-except-colapse{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K') no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}
.b2c-number-except-nocolapse{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyA3LjE0MjgyTDcgMS4xNDI4MkwxIDcuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K') no-repeat center;
  width: 28px;
  height: 28px;
  margin: 9px 16px 0px;
  float: right;
}
.b2c-number-type-text{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 46px;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 8px;
  white-space: nowrap;
}
.b2c-number-type-select option{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
.b2c-number-type-select{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #FFFFFF;
  background: none;
  border: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyAxLjE0MjgyTDcgNy4xNDI4MkwxIDEuMTQyODIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K") no-repeat center;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 18px;
  margin-right: 16px;
  padding-right: 16px;
}
.b2c-search-button{
  width: calc(100% - 50px);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  color: #424242;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  height: 48px;
  background: #FFFFFF;
  -webkit-border-radius:26px;
	border-radius:26px;
  margin: 25px;
}
.b2c-search-button-red{
  color: #FFFFFF !important;
  background: #ED1F24 !important;
}
.b2c-pagination-title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding-top: 4px;
  color: #424242;
  width: 55%;
}
.b2c-pagination-title-sort{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #424242;
  background: #fff;
  -webkit-border-radius:6px;
	border-radius:6px;
  padding: 4px 8px;
  border: none;
  width: 45%;
  text-align: right;

}
.b2c-page-bg{
  background: rgba(242, 242, 242, 1);
}
.b2c-badge{
  font-size: 14px;
}
.b2c-buy-btn{
  border: 2px solid #ED1F24;
  -webkit-border-radius:26px;
	border-radius:26px;
  font-size: 16px;
  color: #ED1F24;
  height: 34px;
  width: 96px;
}
.b2c-page-msisdn{
  color: #000;
  font-weight: 500;
  font-size: 28px;
  padding-bottom: 12px;
  letter-spacing: 2px;
}
.b2c-camket-text{
  font-size:14px;
  margin-top: 12px;
}
.b2c-price-text{
  font-weight: 400;
  font-size: 24px;
  padding-top: 12px;
  letter-spacing: 0.5px;
  color:#424242;
}
.b2c-price-text b{
  color:#ED1F24;
}
.b2c-package-bg-selected{
  background: #f9f9f9;
}
.b2c-package-title-selected{
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  text-align: left;
  width: 50%;
  font-size: 36px;
  color: #ED1F24;
}
.b2c-package-subtitle-selected{
  font-family: 'Roboto';
  font-style: normal;
  text-align: right;
  font-weight: 700;
  font-size: 20px;
  width:50%;
  color: #424242;
  padding-right: 10px;
}
.b2c-package-brief-selected{
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}.b2c-package-brief-selected li{
  margin-top:10px;
}
.b2c-package-brief-selected li::before{
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjM1MTYgMTIuMjkyOUMxMC45NjExIDEyLjY4MzQgMTAuMzI3OSAxMi42ODM0IDkuOTM3NDIgMTIuMjkyOUw5LjE0NDUzIDExLjVDOC44NjgzOSAxMS4yMjM5IDguNDIwNjcgMTEuMjIzOSA4LjE0NDUzIDExLjVDNy44NjgzOSAxMS43NzYxIDcuODY4MzkgMTIuMjIzOSA4LjE0NDUzIDEyLjVMOS45Mzc0MiAxNC4yOTI5QzEwLjMyNzkgMTQuNjgzNCAxMC45NjExIDE0LjY4MzQgMTEuMzUxNiAxNC4yOTI5TDE1LjE0NDUgMTAuNUMxNS40MjA3IDEwLjIyMzkgMTUuNDIwNyA5Ljc3NjE0IDE1LjE0NDUgOS41QzE0Ljg2ODQgOS4yMjM4NiAxNC40MjA3IDkuMjIzODYgMTQuMTQ0NSA5LjVMMTEuMzUxNiAxMi4yOTI5WiIgZmlsbD0iI0VEMUYyNCIvPgo8cGF0aCBkPSJNMjIgMTEuNUMyMiAxNy4yOTkgMTcuMjk5IDIyIDExLjUgMjJDNS43MDEwMSAyMiAxIDE3LjI5OSAxIDExLjVDMSA1LjcwMTAxIDUuNzAxMDEgMSAxMS41IDFDMTcuMjk5IDEgMjIgNS43MDEwMSAyMiAxMS41WiIgc3Ryb2tlPSIjRUQxRjI0IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==');
  padding-right: 10px;
}
.b2c-package-brief-selected .pack-txt{
  text-align: left;
}
.b2c-package-title{
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  text-align: center;
  width: 100%;
  font-size: 36px;
  color: #ED1F24;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMzMiIHZpZXdCb3g9IjAgMCAzMyAzMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjU5NDMgMTcuOTQ5N0MxNi4yMDM4IDE4LjM0MDMgMTUuNTcwNiAxOC4zNDAzIDE1LjE4MDEgMTcuOTQ5N0wxMy43NjU5IDE2LjUzNTVDMTMuMzc1MyAxNi4xNDUgMTIuNzQyMiAxNi4xNDUgMTIuMzUxNiAxNi41MzU1VjE2LjUzNTVDMTEuOTYxMSAxNi45MjYxIDExLjk2MTEgMTcuNTU5MiAxMi4zNTE2IDE3Ljk0OTdMMTUuMTgwMSAyMC43NzgyQzE1LjU3MDYgMjEuMTY4NyAxNi4yMDM4IDIxLjE2ODcgMTYuNTk0MyAyMC43NzgyTDIyLjI1MTEgMTUuMTIxM0MyMi42NDE3IDE0LjczMDggMjIuNjQxNyAxNC4wOTc2IDIyLjI1MTEgMTMuNzA3MVYxMy43MDcxQzIxLjg2MDYgMTMuMzE2NiAyMS4yMjc0IDEzLjMxNjYgMjAuODM2OSAxMy43MDcxTDE2LjU5NDMgMTcuOTQ5N1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMiAxNi41QzMyIDI1LjA2MDQgMjUuMDYwNCAzMiAxNi41IDMyQzcuOTM5NTkgMzIgMSAyNS4wNjA0IDEgMTYuNUMxIDcuOTM5NTkgNy45Mzk1OSAxIDE2LjUgMUMyNS4wNjA0IDEgMzIgNy45Mzk1OSAzMiAxNi41WiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: 10px;
}
.b2c-package-subtitle{
  font-family: 'Roboto';
  font-style: normal;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  color: #424242;
}
.b2c-package-brief{
  font-size: 18px;
  line-height: 26px;
  color: #424242;
  text-align: left;
}.b2c-package-brief li{
  margin-top:10px;
}
.b2c-package-brief li::before{
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNyAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNTc0ODkgOC45MDE1OUM4LjE4NDM2IDkuMjkyMTIgNy41NTEyIDkuMjkyMTIgNy4xNjA2OCA4LjkwMTU5TDYuNzU5MDkgOC41QzYuNTU0OTggOC4yOTU5IDYuMjI0MDYgOC4yOTU5IDYuMDE5OTYgOC41VjguNUM1LjgxNTg1IDguNzA0MTEgNS44MTU4NSA5LjAzNTAzIDYuMDE5OTYgOS4yMzkxM0w3LjE2MDY4IDEwLjM3OTlDNy41NTEyIDEwLjc3MDQgOC4xODQzNiAxMC43NzA0IDguNTc0ODkgMTAuMzc5OUwxMS4xOTM5IDcuNzYwODdDMTEuMzk4IDcuNTU2NzcgMTEuMzk4IDcuMjI1ODUgMTEuMTkzOSA3LjAyMTc0VjcuMDIxNzRDMTAuOTg5OCA2LjgxNzY0IDEwLjY1ODggNi44MTc2NCAxMC40NTQ3IDcuMDIxNzRMOC41NzQ4OSA4LjkwMTU5WiIgZmlsbD0iIzQyNDI0MiIvPgo8cGF0aCBkPSJNMTYgOC41QzE2IDEyLjY0MjEgMTIuNjQyMSAxNiA4LjUgMTZDNC4zNTc4NiAxNiAxIDEyLjY0MjEgMSA4LjVDMSA0LjM1Nzg2IDQuMzU3ODYgMSA4LjUgMUMxMi42NDIxIDEgMTYgNC4zNTc4NiAxNiA4LjVaIiBzdHJva2U9IiM0MjQyNDIiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K');
  padding-right: 10px;
}
.b2c-package-brief .pack-txt,.b2c-package-brief-selected .pack-txt{
  text-align: left;
}
.b2c-package-brief-selected .pack-txt,.b2c-package-brief .pack-txt{
  margin-bottom: 10px;
}
.b2c-float-bg{
  height: 120px;
  background: rgba(237, 31, 36, 1);
  position: absolute;
}
.b2c-cart-price{
  position: absolute;
  min-width: 100px;
  width: auto;
  height: 63px;
  border-radius: 10px;
  -webkit-border-radius:10px;
  right: 20px;
  background: #18D3C9;
  font-style: italic;
  font-size: 16px;
  color: #fff;
  font-weight: 900;
  text-align: center;
  padding: 13px;
}
.b2c-cart-price span{
  font-size: 24px;
}
.b2c-cart-add-more{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}
.b2c-cart-info-title{
  height: 46px;
  background: #eaeaea;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #222222;
  padding:  10px 24px;
  border-bottom: 1px solid #e0e0e0;
}
.b2c-agree-text button{
  font-weight: 500;
  font-size: 16px;
  color: #222222;
}
.b2c-store-colapse{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAyTDYuNSA3TDIgMiIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=') no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}
.b2c-store-nocolapse{
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSA3TDYuNSAyTDIgNyIgc3Ryb2tlPSIjNTc1NzU3IiBzdHJva2Utd2lkdGg9IjIuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=') no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 18px;
}
.b2c-bg-selected{
  background: #17E0D5;
}
.b2c-bg-selected .b2c-page-msisdn,.b2c-bg-selected .b2c-price-text,.b2c-bg-selected .b2c-camket-text,.b2c-bg-selected .b2c-price-text b{
  color: #fff;
}
.b2c-text-solo h1{
  font-size: 28px;
  letter-spacing: 3px;
  font-weight: 500;
  color: #fff;
}
.b2c-text-solo p{
  color: #fff;
  font-size: 16px;
  padding: 0 10%;
}
.b2c-search-button-red:disabled{
  background: #999999 !important;
}
.b2c-cart-bg-del{
  border-radius: 26.8px;
  -webkit-border-radius:26.8px;
  border: 1.5px solid #ED1F24;
  padding: 6px 16px;
}
.b2c-price-texts{
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.b2c-rate-text{
  font-size: 20px;
  line-height: 1.4;
}
.vue-star-rating{
  display: block !important;
}
.b2c-ghtk-fee{
  font-size: 14px;
  text-decoration-line: line-through !important;
}
.b2c-o-h{
  max-width: 100%;
  overflow-x: hidden;
}
.b2c-shipment-input{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  height: 45px;
  color: #ED1F24;
  width: 100%;
  border: none;
  background: #fff;
  -webkit-border-radius:8px;
	border-radius:8px;
  text-align: center;
  letter-spacing: 2px;
  padding-left: 10px;
}
.b2c-shipment-input::placeholder{
  font-size: 20px;
  font-weight: 400;
}
.min-400{
  min-height: 400px;
}
.b2c-tab-shipment-col1{
  width: 60px;
  height: 60px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTExIiB2aWV3Qm94PSIwIDAgMjAgMTExIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSIxMCIgY3k9IjEwIiByPSIxMCIgZmlsbD0iI0VBNTQ1NSIgZmlsbC1vcGFjaXR5PSIwLjEyIi8+CjxjaXJjbGUgY3g9IjEwIiBjeT0iMTAiIHI9IjYiIGZpbGw9IiNFRDFGMjQiLz4KPGxpbmUgeDE9IjEwIiB5MT0iMjAiIHgyPSIxMCIgeTI9IjExMSIgc3Ryb2tlPSIjQzRDNEM0Ii8+Cjwvc3ZnPgo=') no-repeat center top;
}
.b2c-tab-shipment-col2 h3{
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}
.b2c-tab-shipment-col2 p{
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
  padding-top: 6px;
  padding-bottom: 16px;
}
@media screen and (max-width:450px) {

}
@media screen and (min-width:451px) {
  .b2c-page-bg .bg-primary >div{
    padding-top: 50px;
  }
  .b2c-page-bg .bg-primary >div,.b2c-page-bg .card{
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}
.shop-title{
  font-weight: 700;
  font-size: 24px;
  line-height: 41px;
  padding-top:23px;
  align-items: center;
  text-align: center;
  width: 100%;
  color: #424242;
}
.shop-title1{
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding-top:10px;
}
.shop-title2{
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding-top:10px;
}
</style>
